import { Box, Grid, Typography } from '@mui/material'
import { HTMLMapper } from '@trr/html-mapper'
import configService from 'utils/configService'
import {
  createImageAltTextFromFileName,
  getFullImageUrl,
  getStandardSrcset,
} from '@trr/internal-helpers'
import { useCachedSettingsState } from 'state/PublicWebApi'

export const MfError = () => {
  const { MEDIA_URL } = configService.config

  const httpErrorContent = useCachedSettingsState().errorPageSettings['mfError']

  return (
    <Box
      className="mfError"
      data-testid={`error${httpErrorContent.errorCode.replace(' ', '')}`}
      sx={{ flexGrow: 1, maxWidth: '1140px', m: 'auto' }}
    >
      <Grid container columns={20}>
        <Grid item xs={20} md={12}>
          <Typography
            sx={{ typography: { xs: 'h3', md: 'h1' } }}
            component={'h1'}
            mb={4}
          >
            {httpErrorContent.heading}
          </Typography>
          <HTMLMapper
            mediaUrl={configService.config.MEDIA_URL}
            body={httpErrorContent.ingress}
          />
        </Grid>
        {httpErrorContent.image && (
          <Grid item md={8} sx={{ display: { xs: 'none', md: 'block' } }}>
            <img
              src={getFullImageUrl(MEDIA_URL, httpErrorContent.image, 680)}
              srcSet={getStandardSrcset(MEDIA_URL, httpErrorContent.image, [
                768,
              ])}
              alt={createImageAltTextFromFileName(httpErrorContent.image)}
              loading="lazy"
            />
          </Grid>
        )}
      </Grid>
    </Box>
  )
}

export default MfError
