import { Box, Typography } from '@mui/material'

import { CardItem, CardProps } from 'apps/Site/components/Card'

import {
  Row,
  CardsWrapper,
  HeadingAndPreambleWrapper,
} from './CardsBlock.styles'

export interface CardsBlockProps {
  content: {
    heading?: string
    preamble?: string
    color?: string
    useColor: boolean
    cards: Cards[]
  }
}

export interface Cards {
  properties: CardProps
  type: string
}

export const separateCardsIntoRows = (cards: Cards[]): Cards[][] => {
  const cardsInRowsOfMaxThreeCards = cards?.reduce<Cards[][]>(
    (acc, card, index) => {
      if (index % 3 === 0) {
        acc.push([])
      }
      acc[acc.length - 1].push(card)
      return acc
    },
    []
  )

  const lastRow =
    cardsInRowsOfMaxThreeCards?.[cardsInRowsOfMaxThreeCards.length - 1]
  if (cardsInRowsOfMaxThreeCards.length > 1 && lastRow.length === 1) {
    const secondToLastCard =
      cardsInRowsOfMaxThreeCards[cardsInRowsOfMaxThreeCards.length - 2]?.pop()
    lastRow?.unshift(secondToLastCard)
  }

  return cardsInRowsOfMaxThreeCards
}

const CardsBlock = ({
  content: { heading, preamble, color, useColor, cards },
}: CardsBlockProps) => {
  const cardsInRows = separateCardsIntoRows(cards)
  const shouldShowImages: boolean = cards.every((card) => card.properties.image)

  return (
    <Box display="flex" flexDirection="column" component="section">
      <HeadingAndPreambleWrapper>
        {heading && (
          <Typography variant="h3" component="h2" mb={{ xs: 3, md: 1 }}>
            {heading}
          </Typography>
        )}
        {preamble && <Typography variant="body1">{preamble}</Typography>}
      </HeadingAndPreambleWrapper>
      {cardsInRows?.length > 0 && (
        <CardsWrapper>
          {cardsInRows.map((row, index) => (
            <Row key={'row-' + `${index}`} data-testid="card-row">
              {row.map((card) => (
                <CardItem
                  numberOfCardsInRow={row.length}
                  key={'card-' + card.properties.heading}
                  useImage={shouldShowImages}
                  color={color}
                  useColor={useColor}
                  {...card.properties}
                  isEditorialCardBlock={card.type === 'EditorialCardBlock'}
                />
              ))}
            </Row>
          ))}
        </CardsWrapper>
      )}
    </Box>
  )
}

export default CardsBlock
