import { SyntheticEvent, useCallback } from 'react'
import { Link, styled } from '@mui/material'
import { useHistory } from 'react-router-dom'
import { TableOfContentLink } from 'state/PublicWebApi/Content'

interface TableOfContentsLinkProps {
  item: TableOfContentLink
  isActive?: boolean
}

interface TableOfContentLinkStyledProps {
  isInView: boolean
}

const TableOfContentLinkStyled = styled(Link)<TableOfContentLinkStyledProps>(
  ({ theme, isInView }) => ({
    display: 'flex',
    scrollBehavior: 'smooth',
    color: theme.palette.text.primary,
    padding: theme.spacing(1, 2),
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(1.5, 1.5, 1.5, 2),
      ...(isInView && {
        borderRadius: '48px',
        backgroundColor: theme.palette.surface?.purple,
        color: theme.palette.primary?.main,
      }),
    },
    '&:hover': {
      borderRadius: '48px',
      backgroundColor: theme.palette.surface?.purple,
      color: theme.palette.primary?.main,
    },
  })
)

export const TableOfContentsLink = ({
  item,
  isActive,
}: TableOfContentsLinkProps) => {
  // eslint-disable-next-line @typescript-eslint/unbound-method
  const { replace } = useHistory()
  const clickHandler = useCallback(
    (e: SyntheticEvent) => {
      e.preventDefault()
      const target = document.getElementById(`${item.headingId}`)
      if (target) {
        replace({ hash: `${item.headingId}` })
        target.scrollIntoView({ behavior: 'smooth', block: 'start' })
      }
    },
    [item.headingId, replace]
  )

  return (
    <li>
      <TableOfContentLinkStyled
        isInView={isActive}
        onClick={clickHandler}
        href={`#${item.headingId}`}
      >
        {item.text}
      </TableOfContentLinkStyled>
    </li>
  )
}
