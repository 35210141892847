import {
  createImageAltTextFromFileName,
  getFullImageUrl,
  isLinkExternal,
  LazyLoadWrapper,
} from '@trr/internal-helpers'
import { Typography, CardMedia, CardActions } from '@mui/material'
import Launch from '@mui/icons-material/Launch'

import {
  LinkText,
  ExternalLinkIconWrapper,
  CardWrapper,
  CardActionAreaWrapper,
  CardContentWrapper,
  ImageWrapper,
} from './Card.styles'
import configService from 'utils/configService'

export interface CardProps {
  cardCategory?: string
  heading: string
  image?: string
  useImage?: boolean
  preamble: string
  link: {
    text: string
    url: string
  }
}

export interface CardExtendedProps extends CardProps {
  color?: string
  useColor: boolean
  isEditorialCardBlock: boolean
  numberOfCardsInRow?: number
  fullHeight?: boolean
}

export const CardItem = ({
  cardCategory,
  numberOfCardsInRow = 3,
  heading,
  preamble,
  useImage,
  image,
  link,
  color,
  useColor,
  isEditorialCardBlock,
  fullHeight,
}: CardExtendedProps) => {
  const { MEDIA_URL } = configService.config
  const externalLink = link.url ? isLinkExternal(link.url) : false

  return (
    <CardWrapper
      backgroundColor={color}
      useColor={useColor}
      sx={{ height: fullHeight && '100%' }}
    >
      <CardActionAreaWrapper
        href={link.url}
        target={externalLink ? '__blank' : undefined}
        rel={externalLink ? 'noreferrer' : undefined}
        data-testid={
          externalLink ? 'external-actionarea-wrapper' : 'actionarea-wrapper'
        }
      >
        {useImage && image && (
          <ImageWrapper cardsInRow={numberOfCardsInRow}>
            <LazyLoadWrapper className="LazyLoadWrapper">
              <CardMedia
                data-testid={'card-image'}
                component="img"
                image={getFullImageUrl(MEDIA_URL, image, 768)}
                alt={createImageAltTextFromFileName(image)}
              />
            </LazyLoadWrapper>
          </ImageWrapper>
        )}
        <CardContentWrapper>
          {cardCategory && (
            <Typography
              variant="subtitle2"
              component="p"
              color="text.secondary"
              mb="8px"
            >
              {cardCategory}
            </Typography>
          )}
          <Typography variant="h5" component="h3" mb="16px">
            {heading}
          </Typography>
          <Typography variant="body2" component="p">
            {preamble}
          </Typography>
        </CardContentWrapper>
        <CardActions sx={{ paddingLeft: '24px' }}>
          {externalLink && (
            <ExternalLinkIconWrapper aria-hidden="true">
              <Launch fontSize={'small'} color="primary" />
            </ExternalLinkIconWrapper>
          )}
          {isEditorialCardBlock ? (
            <LinkText
              variant="body1"
              data-testid={externalLink ? 'external-card-link' : 'card-link'}
              aria-label={
                externalLink ? link.text + ` (öppnas i ny flik)` : link.text
              }
            >
              {link.text}
            </LinkText>
          ) : (
            <LinkText
              color="primary.main"
              variant="body1"
              data-testid={
                isLinkExternal(link.url) ? 'external-card-link' : 'card-link'
              }
              aria-label={
                isLinkExternal(link.url)
                  ? `Läs mer om ${heading} (öppnas i ny flik)`
                  : `Läs mer om ${heading}`
              }
            >
              Läs mer
            </LinkText>
          )}
        </CardActions>
      </CardActionAreaWrapper>
    </CardWrapper>
  )
}
