import { useState } from 'react'
import { Box, Link, useTheme } from '@mui/material'
import DownloadForOffline from '@mui/icons-material/DownloadForOffline'
import configService from 'utils/configService'
import { setupHeaders } from 'state/helpers'

const ComplementingReportSection = () => {
  const [loading, setLoading] = useState<boolean>(false)
  const { palette } = useTheme()

  const onDownloadClick = () => {
    setLoading(true)

    const fetchFn = async () => {
      const headers = await setupHeaders(new Headers())
      fetch(`${configService.config.API_GATEWAY}/jmt/report`, {
        headers,
      })
        .then(async (res) => {
          window
            .open(window.URL.createObjectURL(await res.blob()), '_blank')
            ?.focus()
          setLoading(false)
        })
        .catch((e) => {
          setLoading(false)
          console.log(e)
        })
    }
    void fetchFn()
  }
  return (
    <Box>
      <Link
        component={'button'}
        disabled={loading}
        onClick={onDownloadClick}
        sx={{
          '&[disabled]': {
            color: palette.action.disabled,
            pointerEvents: 'none',
          },

          display: 'flex',
          alignItems: 'center',
          mb: 2,
          '&:hover': {
            textDecoration: 'underline',
          },
        }}
      >
        <DownloadForOffline sx={{ mr: 1 }} /> Ladda ned resultatrapport
      </Link>
    </Box>
  )
}

export default ComplementingReportSection
