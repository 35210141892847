import { useCallback, useRef } from 'react'
import { ScrollWrapper } from 'apps/Site/Epi/ScrollWrapper'
import { IContentBlock } from 'apps/Site/Epi/BlockMapper/BlockMapper'
import { Box, styled, Typography } from '@mui/material'
import { date as dateFormatter } from '@trr/frontend-datetime'
import { CardProps } from 'apps/Site/components/Card'
import ArticlesCardGrid from 'apps/Site/components/ArticlesCardGrid/ArticlesCardGrid'

interface NewsListItem extends IContentBlock {
  startPublish: string
  url: string
  properties: {
    customCategory?: string
    puffView?: {
      heading?: string
      longIntro?: string
      mainIntro?: string
      image?: string
    }
    introduction: {
      heading?: string
      text?: string
    }
  }
}

export interface NewsListProps {
  heading: string
  text?: string
  news?: NewsListItem[]
  fallbackUrl?: string
}

const Introduction = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(3),
  marginBottom: theme.spacing(7),
  maxWidth: '752px',
}))

const convertNewsToCards = (
  news: NewsListItem[],
  fallbackUrl: string
): CardProps[] => {
  const cards: CardProps[] = news.map(
    ({
      startPublish,
      url,
      properties: { customCategory, puffView, introduction },
    }) => {
      const formattedPublishDate = dateFormatter.format(startPublish, {
        style: 'numeric',
      })
      const category = customCategory
        ? `${formattedPublishDate} • ${customCategory}`
        : formattedPublishDate

      let preamble =
        puffView?.longIntro || puffView?.mainIntro || introduction?.text

      if (preamble.length > 120) {
        preamble = preamble.slice(0, 120).concat('...')
      }

      return {
        heading: puffView?.heading || introduction?.heading,
        preamble: preamble,
        cardCategory: category,
        image: puffView?.image || fallbackUrl,
        useImage: true,
        link: { text: 'Läs mer', url: url },
      }
    }
  )
  return cards
}

const NewsList = ({ heading, text, news, fallbackUrl }: NewsListProps) => {
  const headingRef = useRef(null)

  const newsAsCards = convertNewsToCards(news.slice(1), fallbackUrl)
  const latestNews = news.at(0)
  const latestNewsFormattedPublishDate = dateFormatter.format(
    latestNews.startPublish,
    {
      style: 'numeric',
    }
  )
  const latestNewsCategory = latestNews.properties.customCategory
    ? `${latestNewsFormattedPublishDate} • ${latestNews.properties.customCategory}`
    : latestNewsFormattedPublishDate

  const paginationCallback = useCallback(() => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
    headingRef.current.focus()
  }, [headingRef])

  return (
    <ScrollWrapper>
      <Introduction>
        <Typography
          variant="h1"
          tabIndex={-1}
          ref={headingRef}
          sx={{
            '&:focus': {
              outline: 0,
            },
          }}
        >
          {heading}
        </Typography>
        {text && <Typography variant="preamble">{text}</Typography>}
      </Introduction>
      {news?.length > 0 && (
        <ArticlesCardGrid
          firstArticle={{
            content: {
              heading:
                latestNews.properties.puffView?.heading ||
                latestNews.properties.introduction?.heading,
              body:
                latestNews.properties.puffView?.longIntro ||
                latestNews.properties.puffView?.mainIntro ||
                latestNews.properties.introduction?.text,
              customCategory: latestNewsCategory,
              image: latestNews.properties.puffView?.image || fallbackUrl,
              link: { text: 'Läs mer', url: latestNews.url },
              imagePosition: 'left',
              color: 'blue',
              useColor: true,
            },
          }}
          articles={newsAsCards}
          paginationCallback={paginationCallback}
        />
      )}
    </ScrollWrapper>
  )
}

export default NewsList
