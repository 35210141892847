import { IContentBlock } from 'apps/Site/Epi/BlockMapper/BlockMapper'
import { ScrollWrapper } from 'apps/Site/Epi/ScrollWrapper'
import blockMapper from 'apps/Site/Epi/BlockMapper'
import { Box, Typography, styled } from '@mui/material'

export interface PostListProps {
  heading: string
  blockArea: IContentBlock[]
  text?: string
}

const Introduction = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(3),
  marginBottom: theme.spacing(7),
  maxWidth: '752px',
}))

const BlocksWrapper = styled(Box)(({ theme }) => ({
  '& > *': {
    marginBottom: theme.spacing(7),

    [theme.breakpoints.up('md')]: {
      marginBottom: theme.spacing(10),
    },
  },
}))
const PostList = ({ heading, blockArea, text }: PostListProps) => {
  return (
    <ScrollWrapper>
      <Introduction>
        <Typography variant="h1">{heading}</Typography>
        {text && <Typography variant="preamble">{text}</Typography>}
      </Introduction>
      {blockArea?.length > 0 && (
        <BlocksWrapper>{blockMapper({ blocks: blockArea })}</BlocksWrapper>
      )}
    </ScrollWrapper>
  )
}

export default PostList
