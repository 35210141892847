import configService from 'utils/configService'
import { getCookieValue } from 'utils/cookies'

const port = window.location.port ? `:${window.location.port}` : ''
const currentWindow = `${window.location.protocol}//${window.location.hostname}${port}`

export const getUserManagerSilentRedirectUri = (): string => {
  if (getCookieValue('app-shell-version')) {
    // @ts-expect-error Patch window for test purposes
    return `${currentWindow}${window.SIGN_IN_SILENT_URL}`
  }
  return `${currentWindow}${configService.config.PUBLIC_URL}silent_renew.html`
}

export const getUserManagerRedirectUri = (callbackPRPrefix = ''): string => {
  return `${currentWindow}${callbackPRPrefix}/callback`
}
