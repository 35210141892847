import { Box, CircularProgress } from '@mui/material'
import { featureFlagsApi } from 'state/PublicWebApi/FeatureFlags'
import { SiteErrorPage } from 'features/ErrorHandling/SiteErrorPage'
import { type PropsWithChildren } from 'react'

const FetchFeatureFlags = ({ children }: PropsWithChildren) => {
  const { isSuccess: isFeatureFlagsSuccess, isError: isFeatureFlagsError } =
    featureFlagsApi.endpoints.getFeatureFlags.useQuery()

  if (isFeatureFlagsSuccess) {
    return <>{children}</>
  }
  if (isFeatureFlagsError) {
    return <SiteErrorPage />
  }
  return (
    <Box display="flex" flex="1 1 auto" justifyContent="center" py="10%">
      <CircularProgress />
    </Box>
  )
}

export default FetchFeatureFlags
