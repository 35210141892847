import { useCallback } from 'react'
import ArrowDropDownRounded from '@mui/icons-material/ArrowDropDownRounded'
import ArrowDropUpRounded from '@mui/icons-material/ArrowDropUpRounded'
import { Box, styled } from '@mui/material'

import { MegaNavigationMenuItem } from 'PublicWebApi/Settings/types'
import { NavItemWrapper } from '../HeaderMenuItems.styles'
import FoldoutArea from '../../FoldoutArea'

interface FoldOutItemInterface extends MegaNavigationMenuItem {
  onClick?: () => void
  isFoldoutOpen: boolean
  toggleActiveNavElement: () => void
}

const FoldoutAreaWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  width: '100%',
  position: 'absolute',
  marginTop: theme.spacing(2),
  left: 0,
}))

const FoldoutItem = ({
  title,
  onClick,
  isFoldoutOpen,
  toggleActiveNavElement,
  children,
}: FoldOutItemInterface) => {
  const onClickAction = useCallback(() => {
    onClick?.()
    toggleActiveNavElement?.()
  }, [onClick, toggleActiveNavElement])

  return (
    <Box alignSelf="center" height="100%">
      <NavItemWrapper
        onClick={onClickAction}
        isFoldoutOpen={isFoldoutOpen}
        data-testid={'foldoutitem-' + `${title}`}
        aria-haspopup="true"
        aria-expanded={isFoldoutOpen}
      >
        {title}
        {isFoldoutOpen ? (
          <ArrowDropUpRounded data-testid={'arrowup-icon-' + `${title}`} />
        ) : (
          <ArrowDropDownRounded data-testid={'arrowdown-icon-' + `${title}`} />
        )}
      </NavItemWrapper>
      {isFoldoutOpen && (
        <FoldoutAreaWrapper>
          <FoldoutArea
            foldoutArea={children}
            closeFoldout={toggleActiveNavElement}
          />
        </FoldoutAreaWrapper>
      )}
    </Box>
  )
}

export default FoldoutItem
