import { useState } from 'react'
import { Grid2 as Grid, Pagination, Stack } from '@mui/material'
import PhotoTextBlock, {
  PhotoTextBlockProps,
} from 'apps/Site/Epi/Blocks/PhotoTextBlock/PhotoTextBlock'
import { CardItem, CardProps } from '../Card/Card'

export interface ArticlesCardGridProps {
  firstArticle: PhotoTextBlockProps
  articles: CardProps[]
  paginationCallback: () => void
}

const ArticlesCardGrid = ({
  firstArticle,
  articles,
  paginationCallback,
}: ArticlesCardGridProps) => {
  const [page, setPage] = useState(1)
  const PAGE_SIZE = 12
  const pagesCount = Math.ceil(articles.length / PAGE_SIZE)

  const handlePaginationClick = (value: number) => {
    setPage(value)
    paginationCallback()
    window.scrollTo(0, 0)
  }

  return (
    <Stack alignItems={'center'}>
      {page === 1 && <PhotoTextBlock content={firstArticle.content} />}
      {articles.length > 0 && (
        <Grid container spacing={{ xs: 2, md: 3 }} mb={3}>
          {articles
            .slice((page - 1) * PAGE_SIZE, (page - 1) * PAGE_SIZE + PAGE_SIZE)
            .map((article) => (
              <Grid
                key={`article-item-${article.link.url}`}
                size={{ xs: 12, md: 4 }}
              >
                <CardItem
                  {...article}
                  useColor={false}
                  isEditorialCardBlock={false}
                  fullHeight
                />
              </Grid>
            ))}
        </Grid>
      )}
      {pagesCount > 1 && (
        <Pagination
          count={pagesCount}
          page={page}
          onChange={(e, v) => {
            handlePaginationClick(v)
          }}
        />
      )}
    </Stack>
  )
}

export default ArticlesCardGrid
